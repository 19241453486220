import serviceDefs from '../services/serviceDefs';
import { SERVICES_PATH } from '../constants';
import shared from '../shared';
import { parseAui, parseCallable } from './parse';

export function generateEntityPath(endpoint, serviceAlias) {
  const path = endpointPathLookup(endpoint);
  return ['services', serviceAlias, 'entities', ...path];
}

export function getParentEntityAwarePath(entity, entityAlias='') {
  const { entityId, serviceAlias } = entity;
  const path = [...generatePathRecursively(entity), entityId];

  if (entityAlias) {
    path.push(entityAlias);
  }

  return ['services', serviceAlias, 'entities', ...path];
}

function generatePathRecursively(entity) {
  let path = [];

  if (entity.parentEntity) {
    path = [...generatePathRecursively(entity.parentEntity), entity.parentEntity.entityId];
  }

  return [...path, ...endpointPathLookup(entity.endpoint)];
}

export function endpointPathLookup(endpoint) {
  const path = [];

  do {
    path.unshift(endpoint.label);

    if (endpoint.parentId) {
      path.unshift(endpoint.parentId);
    }

    endpoint = endpoint.parentEndpoint;
  }
  while (endpoint);

  return path;
}

export function skipDisplay(attr, view) {
  return attr && ('display' in attr
          && (!attr.display
              || typeof(attr.display) === "object"
                 && view in attr.display && !attr.display[view]));
}

export function hideOptionalEmptyData(attr, property, entity) {
  return attr?.optional && entity.getIn([property]) === undefined
}

function filterOptions (options, selection) {
  if (!options || options.length === 0) {
    return true;
  }
  if (typeof options === 'string') {
    return options === selection;
  }
  return options.includes?.(selection);
}

export function showInEnv(envs, environment=shared.config.environment) {
  return filterOptions(envs, environment);
}

export function showInAcct(accts, acct=shared.accountId) {
  return filterOptions(accts, acct);
}

export function showForUser(users, username=shared.username) {
  return filterOptions(users, username);
}

export function showByEnvVar(envVar) {
  return Boolean(localStorage.getItem(envVar));
}

export function showInPartition(partitions, partition=shared.config.partition) {
  return filterOptions(partitions, partition);
}

export function shouldHideContent(def) {
  if (!def?.contentFilters) {
    return false;
  }
  return !def.contentFilters.some(filters => {
    let contentFiltersParsed = parseCallable(filters);

    for (let { func, args } of contentFiltersParsed) {
      if (typeof contentFilters[func] === "function") {
        if (!contentFilters[func](...args)) {
          return false;
        }
      }
    }
    return true;
  });
}

export const contentFilters = {
  showInEnv,
  showInAcct,
  showByEnvVar,
  showForUser,
  showInPartition
};

export function orderByDisplay(attributes, view) {
  // Disabling linting here for readability. e.g. aAttrs.displayOrder vs a[1].displayOrder
  // eslint-disable-next-line
  return Object.entries(attributes).sort(([aProp, aAttrs], [bProp, bAttrs]) => {
    const isUndefined = order => order === undefined;
    const aOrder = typeof aAttrs?.displayOrder === 'number'
                   ? aAttrs.displayOrder
                   : typeof aAttrs?.displayOrder === 'object'
                     ? aAttrs.displayOrder[view]
                     : undefined;

    const bOrder = typeof bAttrs?.displayOrder === 'number'
                   ? bAttrs.displayOrder
                   : typeof bAttrs?.displayOrder === 'object'
                     ? bAttrs.displayOrder[view]
                     : undefined;

    if (isUndefined(aOrder) && isUndefined(bOrder)) {
      return 0;
    }
    // a should go before b
    if (!isUndefined(aOrder) && isUndefined(bOrder)) {
      return -1;
    }
    // b should go before a
    if (!isUndefined(bOrder) && isUndefined(aOrder)) {
      return 1;
    }

    // normal sort
    return aOrder - bOrder;
  });
}

function pathLookupByArn(arn, entities, entityType=undefined) {
  for (let [entityAlias, entityDef] of Object.entries(entities)) {
    if (entityDef.arn === arn && (entityType === undefined || entityDef.type === entityType)) {
      return entityAlias;
    }
    if (entityDef.subviews) {
      let path = pathLookupByArn(arn, entityDef.subviews);
      if (path) {
        return path;
      }
    }
  }
  return '';
}

export function getEntityUrlByArn(arn, entityType=undefined) {
  for (let [serviceAlias, serviceDef] of Object.entries(serviceDefs)) {
    let path = pathLookupByArn(arn, serviceDef.entities, entityType);
    if (path) {
      return `/services/${serviceAlias}/${path}`;
    }
  }
  return '';
}

export function getEntityUrlByAui(aui, entityType = undefined) {
  const { arn } = parseAui(aui);
  return getEntityUrlByArn(arn, entityType);
}

export function getEntityReplicateUrl(serviceAlias, entityAlias, parentEntityAlias=undefined) {
  let url = `${SERVICES_PATH}/${serviceAlias}`;

  if (parentEntityAlias) {
    url += `/${parentEntityAlias}/-`;
  }

  return url + `/${entityAlias}/replicate`;
}

import React from "react";
import TMC from '@autonomic/browser-sdk';
import {getParentEntityAwarePath} from '../../../utils/entity';
import moment from 'moment-timezone';
import cn from 'classnames';

import AuButton, {
  BUTTON_TYPE_TERTIARY,
  BUTTON_TYPE_SECONDARY,
} from '@au/core/lib/components/elements/AuButton';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import ConfirmationDialog from '@au/core/lib/components/objects/ConfirmationDialog';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import shared from '../../../shared';
import formatters from '../../../utils/formatters';
import ClientSecretDialog from '../../ClientSecretDialog';
import StatusBadge from '../../StatusBadge';
import SidebarSubviews from "../../SidebarSubview";
import Banner from "../../Banner";
import DefaultList from '../List';

import styles from '../../../css/components/entity_view.module.scss';
import credentialStyles from '../../../css/components/entity/custom/credential_list.module.scss';

export default class CredentialList extends DefaultList {

  endpoint = new TMC.services.Accounts({apiVersion: '1'}).clients;
  iamEndpoint = new TMC.services.Iam({apiVersion: '2'}).authorization;

  constructor(props) {
    super(props);

    const hasData = props.entities.size > 0;
    const { searchText } = this.props;

    const isMobile = props.screenWidth !== 'desktop';

    this.state = {
      errorStatusCode: null,
      errorStatusMessage: null,
      entity: {},
      searchText: searchText,
      timezone: props.timezone,
      confirmEntityIdDelete: null,
      fetching: !hasData,
      updating: false,
      ready: false,
      allDataFetched: false,
      disabled: false,
      filterQueryParams: {},
      filtersCount: 0,
      sidebarExpanded: this.props.sidebarExpanded,
      subviewSidebarOpen: !isMobile,
      cancel: false,
      showReplicateDialog: false,
      showDeleteSuccessDialog: false,
      started: false,
      showResetDialog: false,
      showRollbackDialog: false,
      showDeleteDialog: false,
      showSuccessDialog: false,
      updated: false,
      newIssue: false,
      deleted: false,
      tokens: [],
      deleteError: false,
      resetAuth: '',
      deleteAuth: ''
    };

    this.baseUrl = props.match.url.split('/').slice(0, -1).join('/');

    if (hasData) {
      this.tableDef = this.generateTableDef();
    }

    if (props.parentEntity) {
      this.parentUrl = this.baseUrl.split('/').slice(0, -1).join('/');
    }
  }

  list() {
    this.fetchAuth();
    this.fetchResetAuth(); 

    return this.fetchData();
  }

  fetchData = this.fetchData.bind(this)
  async fetchData() {
    const entityId = this.props.parentEntity.entityId;

    return this.endpoint.credentials(entityId, {pageSize: 2})
    .then(resp => resp, err => err ); 
  }

  fetchAuth = this.fetchAuth.bind(this);
  async fetchAuth() {
    const entityId = this.props.parentEntity.entityId;

    return this.iamEndpoint.inspect({
      "subjectAui": `aui:iam:user/${shared.authClient?._userInfo?.preferred_username || shared.email}`,
      "resourceAui": `aui:account:client/${entityId}`,
      "action": "account:deleteClientCredential"
    }).then(resp => this.setState({deleteAuth: resp.data.status}), err => err);
  }

  fetchResetAuth = this.fetchResetAuth.bind(this);
  async fetchResetAuth() {
    const entityId = this.props.parentEntity.entityId;

    return this.iamEndpoint.inspect({
      "subjectAui": `aui:iam:user/${shared.authClient?._userInfo?.preferred_username || shared.email}`,
      "resourceAui": `aui:account:client/${entityId}`,
      "action": "account:createClientCredential"
    }).then(resp => this.setState({resetAuth: resp.data.status}), err => err);
  }

  onListSuccess = this.onListSuccess.bind(this);
  onListSuccess(resp) {
    const { parentEntity, actions, entityAlias } = this.props;

    const path = [
    ...getParentEntityAwarePath(parentEntity, entityAlias),
    'credentials'
    ];

    actions.listEntitiesSuccess({
    path,
    data: resp.data.items,
    pkField: 'id',
    replace: false
    });
  }

  checkApplicationsUpdate(tokens) {
    const newSecret = moment(tokens[0].tokenIssuedTime).format('YYYY-MM-DD HH:mm:ss');
    const oldSecret = moment(tokens[1].tokenIssuedTime).format('YYYY-MM-DD HH:mm:ss');
    let updated = false;
    let newIssue = false;

    if (newSecret != 'Invalid date') {
      newIssue = true;
      if (oldSecret === 'Invalid date') {
        updated = true;
      }
      else if (moment(oldSecret).isBefore(newSecret)) {
        updated = true;
      }
    }
    this.setState({updated: updated, newIssue: newIssue});
  }

  toggleNewSecretDialog = () => {
    this.setState(prevState => ({showSecretDialog: !prevState.showSecretDialog}));
    location.reload();
  }

  toggleRollbackDialog = () => {
    this.setState(prevState => ({showRollbackDialog: !prevState.showRollbackDialog}));
  }

  toggleDeleteDialog = () => {
    this.setState(prevState => ({showDeleteDialog: !prevState.showDeleteDialog}));
  }

  deleteSuccess = () => {
    this.setState({showDeleteDialog: false});
    location.reload();
  }

  toggleSuccessDialog = () => {
    this.setState(prevState => ({showSuccessDialog: !prevState.showSuccessDialog}));
    location.reload(); 
  }

  resetClientSecret = () => {
    const entityId = this.props.parentEntity.entityId;

    if (entityId) {
      this.endpoint.resetClientSecret(entityId)
      .then((response) => {
        this.setState({
        showSecretDialog: true,
        showResetDialog: false,
        clientSecret: response.data.secret
        });
      }, err => createResponseAlertMessage(err));
    }
  }

  handleStartButtonClick = this.handleStartButtonClick.bind(this);
  handleStartButtonClick() {
    this.setState({started: true});
  }

  handleRollback = this.handleRollback.bind(this);
  handleRollback() {
    const { entities } = this.props;
    const entityId = this.props.parentEntity.entityId;
    const tokens = Object.values(entities.toJS());
    if (entityId) {
      this.endpoint.getCredentialsEndpoint(entityId).delete(tokens[0].id).then(resp => resp, (err) => {createResponseAlertMessage(err), this.setState({deleteError: true})});
    }
    this.setState({showSuccessDialog: true, showRollbackDialog: false, updated: false, deleted: false});
  }

  handleDelete = this.handleDelete.bind(this);
  handleDelete() {
    const { entities } = this.props;
    const entityId = this.props.parentEntity.entityId;
    const tokens = Object.values(entities.toJS());
    if (entityId) {
      this.endpoint.getCredentialsEndpoint(entityId).delete(tokens[1].id).then(resp => resp, (err) => {createResponseAlertMessage(err), this.setState({deleteError: true})});
    }
    this.setState({showDeleteDialog: false, deleted: true, updated: false});
  }

  renderDialogs() {
    const dialogs = [];
    dialogs.push(this.renderNewSecretDialog());
    dialogs.push(this.renderRollbackDialog());
    dialogs.push(this.renderDeleteDialog());
    dialogs.push(this.renderSuccessDialog());
    return dialogs;
  }
    
  renderNewSecretDialog = () => {
    const {parentEntity} = this.props;
    const {clientSecret, showSecretDialog} = this.state;

    if (!showSecretDialog) {
      return false;
    }

    return (
      <ClientSecretDialog
        key="client_new_secret_dialog"
        clientId={parentEntity.entity?.toJS().clientId || parentEntity.entityId}
        clientSecret={clientSecret}
        onClose={this.toggleNewSecretDialog}
    />
    );
  }

  renderRollbackDialog = () => {
    const { showRollbackDialog, tokens } = this.state;

    if (!showRollbackDialog) {
      return false;
    }

    const credentialId = tokens[0]?.id;

    return (
      <ConfirmationDialog
        type="alert"
        titleId="au.client.credential.rollback"
        confirmDisplayId="au.client.credential.proceed"
        onConfirm={this.handleRollback}
        onCancel={this.toggleRollbackDialog}
        size='small'
        >
        <div className={credentialStyles.dialog_credential}>
          <AutoIntl displayId='au.client.credential.delete.id' tag="div" className={credentialStyles.credential_label}/>
          <AutoIntl displayString={credentialId} className={credentialStyles.credential_value}/>
        </div>
        <AutoIntl displayId="au.client.credential.rollbackDialog" tag="div"/>
      </ConfirmationDialog>
    )
  }

  renderSuccessDialog = () => {
    const { showSuccessDialog, deleteError } = this.state;

    if (!showSuccessDialog || deleteError) {
      return false;
    }

    return (
      <ConfirmationDialog
        type="success"
        titleId='au.client.credential.success'
        confirmDisplayId='au.client.credential.successButton'
        onConfirm={this.toggleSuccessDialog}
        onCancel={this.toggleSuccessDialog}
        showCancelButton={false}
        size='small'
        >
        <AutoIntl displayId='au.client.credential.successDialog' tag="div"/>    
      </ConfirmationDialog>
    )
  }

  renderDeleteDialog = () => {
    const { showDeleteDialog, tokens } = this.state;

    const credentialId = tokens[1]?.id; 

    if (!showDeleteDialog) {
      return false;
    }

    return (
      <ConfirmationDialog
        type="alert"
        titleId="au.client.credential.deleteCredential"
        confirmDisplayId="au.client.credential.proceed"
        onConfirm={this.handleDelete}
        onCancel={this.toggleDeleteDialog}
        size='small'
        >
        <div className={credentialStyles.dialog_credential}><AutoIntl displayId='au.client.credential.delete.id' tag="div" className={credentialStyles.credential_label}/><AutoIntl displayString={credentialId} className={credentialStyles.credential_value}/></div>
        <AutoIntl displayId="au.client.credential.deleteDialog" tag="div"/>
      </ConfirmationDialog>
    )
  }

  renderCredential(token, index) {
    const { updated, tokens, deleted, deleteError } = this.state;
    const rollback = index === 0 && tokens.length > 1;
    const deleteSuccess = deleted && !deleteError;
    const canDelete = this.canDelete(token, index);

    if (deleteSuccess && index === 1) {
      return false;
    }

    return (
      <div>
        {deleteSuccess &&
          <div className={credentialStyles.title_div}>
            <AutoIntl displayId={'au.client.credential'} className={credentialStyles.title}/> 
          </div>
        }
        {rollback && !deleteSuccess &&
          <div className={credentialStyles.title_div}>
            <AutoIntl displayId={'au.client.credentialLatest'} className={credentialStyles.title}/> 
            {!updated && canDelete && 
              <div className={credentialStyles.rollback_btn}>
                <AutoIntl displayId={'au.client.credential.rollback'} onClick={this.toggleRollbackDialog} tag="a"/>
              </div>
            }
          </div>
        }
        {!rollback &&
          <div className={credentialStyles.title_div}>
            <AutoIntl displayId={'au.client.credential'} className={credentialStyles.title}/> 
            {updated && tokens.length > 1 && canDelete &&
              <div className={credentialStyles.delete_btn}>
                <AutoIntl displayId={'au.client.credential.delete'} onClick={this.toggleDeleteDialog} tag="a"/>
              </div>
            }
          </div>
        }
        <table className={styles.rows} ref={ref => this.tableRef = ref}>
          <tbody>
            <tr ref={ref => this.rowRef = ref} >
              <td className={cn(styles.column, styles.label, credentialStyles.table_cell)}>
                <AutoIntl displayId={'au.client.credentialId'} />
              </td>
              <td className={styles.fieldvalue}>
                <AutoIntl displayString={token.id} />
              </td>
            </tr>
            <tr ref={ref => this.rowRef = ref} >
              <td className={cn(styles.column, styles.label, credentialStyles.table_cell)}>
                <AutoIntl displayId={`au.entity.attr.tokenIssuedTime`} />
              </td>
              <td className={styles.fieldvalue}>
                <AutoIntl displayId={'au.entity.attr.timeValue'} values={{time: formatters.date({value: token.tokenIssuedTime})}} />
              </td>
            </tr>
            <tr ref={ref => this.rowRef = ref} >
              <td className={cn(styles.column, styles.label, credentialStyles.table_cell)}>
                <AutoIntl displayId={`au.entity.attr.createTime`} />
              </td>
              <td className={styles.fieldvalue}>
                <AutoIntl displayId={'au.entity.attr.timeValue'} values={{time: formatters.date({value: token.createTime})}} />
              </td>
            </tr>
            <tr ref={ref => this.rowRef = ref} >
              <td className={cn(styles.column, styles.label, credentialStyles.table_cell)}>
                <AutoIntl displayId={`au.entity.attr.updateTime`} />
              </td>
              <td className={styles.fieldvalue}>
                <AutoIntl displayId={'au.entity.attr.timeValue'} values={{time: formatters.date({value: token.updateTime})}} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  renderInfoSection() {
    const { tokens, newIssue, deleted } = this.state;
    const rotating = tokens.length > 1;

    return (
      <div className={credentialStyles.secret_rotation}>
        <AutoIntl displayId={'au.client.secretRotation'} className={credentialStyles.title}/>
        <div className={credentialStyles.info_section}>
          <div className={credentialStyles.step}>
            <div className={credentialStyles.head}>
              <AutoIntl displayId={'au.client.rotation.step1'} className={credentialStyles.step_header} />
              {rotating && <StatusBadge icon={'complete'} displayId={'au.client.rotation.complete'}/> }
            </div>
            <AutoIntl displayId={'au.client.rotation.step1.info'} className={credentialStyles.step_description}/>
            {!rotating && <AuButton className={credentialStyles.generate_button} type={BUTTON_TYPE_SECONDARY} displayId={'au.client.rotation.generateSecret'} onClick={this.resetClientSecret}/>}
          </div>
          <div className={credentialStyles.step}> 
            <div className={credentialStyles.head}>
              <AutoIntl displayId={'au.client.rotation.step2'} className={credentialStyles.step_header}/>
              {newIssue && <StatusBadge icon={'complete'} displayId={'au.client.rotation.complete'}/> }
            </div>
            <AutoIntl displayId={'au.client.rotation.step2.info'} className={credentialStyles.step_description}/>
          </div>
          <div className={credentialStyles.step}>
            <div className={credentialStyles.head}>
              <AutoIntl displayId={'au.client.rotation.step3'} className={credentialStyles.step_header}/>
              {deleted && <StatusBadge icon={'complete'} displayId={'au.client.rotation.complete'}/>}
            </div>
            <AutoIntl displayId={'au.client.rotation.step3.info'} className={credentialStyles.step_description}/>
          </div>
        </div>
      </div>
    )
  }

  canDelete(token) {
    const { deleteAuth } = this.state;

    if (token.tokenIssuedTime === null) {
      return true;
    }

    const tokenIssueTime = moment(token.tokenIssuedTime);
    var currentTime = moment(new Date());
    // check if credential has been used within the last 3 days, if so, client cannot delete
    const diff = currentTime.diff(tokenIssueTime, 'hours');
    
    if (diff >= 72 && deleteAuth === "ALLOW") {
      return true;
    }
    else {
      return false;
    }
  }

  renderContent() {
    const { screenWidth } = this.props;
    const { started, updated, deleted, resetAuth } = this.state;
    const tokens = Object.values(this.props.entities.toJS());
    this.setState({tokens: tokens});
    const isMobile = screenWidth !== "desktop";
    const open = started || updated || deleted || tokens.length > 1;
    const canReset = resetAuth === "ALLOW";

    if (tokens.length > 1) {
      this.checkApplicationsUpdate(tokens);
    }

    const bannerButton = <AuButton className={canReset ? credentialStyles.visible : credentialStyles.hidden} type={BUTTON_TYPE_TERTIARY} displayId={'au.client.startRotation'} onClick={this.handleStartButtonClick}/>;

    return (
      <div className={credentialStyles.container}>
        <SidebarSubviews
          navLinks={this.getNavLinks()}
          portalRef={this._leftSidebarRef}
          open={this.state.subviewSidebarOpen}
          setOpen={this.setSubviewSidebar}
          isMobile={isMobile}
        />
        <div className={cn(styles.content, credentialStyles.content)}>
          {!open && <Banner type='info-square' displayId='au.client.credential.securityTip' className={cn(styles.banner, credentialStyles.banner)} children={bannerButton}/> }
          {open && this.renderInfoSection()}
          {tokens.length != 0 && tokens.map((token, index) => (this.renderCredential(token, index)))}
        </div>
        {this.renderDialogs()}
      </div>
    )
  }
}


import React from 'react';
import cn from 'classnames';
import * as T from 'prop-types';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import StateIcon from '../components/StateIcon';
import { STATUS_UNKNOWN } from '../constants';
import styles from '../css/components/widget_icon.module.scss';

export const ICON_NOT_BOUND      = 'not_bound';
export const ICON_BOUND          = 'bound';
export const ICON_CONNECTED      = 'connected';
export const ICON_DEEP_SLEEP     = 'deep_sleep';
export const ICON_NOT_CONNECTED  = 'disconnected';
export const ICON_COUNT          = 'count';
export const ICON_MEMBER_VEHICLE = 'member_vehicle';
export const ICON_DEVICE         = 'device';
export const ICON_FLOWS          = 'flows';
export const ICON_TAPS           = 'taps';
export const ICON_CLOUD          = 'cloud';
export const ICON_DELIVERY       = 'delivery';
export const ICON_ENCRYPTION_KEY = 'key';
export const ICON_AUTH_CRED      = 'auth';
export const ICON_UNKNOWN        = 'unknown';
export default class WidgetIcon extends StateIcon {
  static icons = [
    ICON_NOT_BOUND,
    ICON_BOUND,
    ICON_CONNECTED,
    ICON_DEEP_SLEEP,
    ICON_NOT_CONNECTED,
    ICON_MEMBER_VEHICLE,
    ICON_DEVICE,
    ICON_CLOUD,
    ICON_TAPS,
    ICON_FLOWS,
    ICON_ENCRYPTION_KEY,
    ICON_AUTH_CRED,
    ICON_COUNT,
    ICON_DELIVERY
  ];

  static propTypes = {
    icon: T.oneOf([...StateIcon.icons, ...WidgetIcon.icons])
  }

  static defaultProps = {
    icon: STATUS_UNKNOWN
  }

  // Extracted for overrides
  getIconStyles() {
    const { icon } = this.props;
    return styles[icon];
  }

  render() {
    const { icon } = this.props;
    const isStateIcon = icon && StateIcon.icons.includes(icon);
    let isConnectivityIcon;
    let iconClassName;
    if (!isStateIcon) {
      isConnectivityIcon = icon && WidgetIcon.icons.includes(icon);
    }

    if (isStateIcon || isConnectivityIcon) {
      iconClassName = this.getIconStyles();
    } else {
      iconClassName = styles[ICON_UNKNOWN];
    }
    
    return (
      <div className={cn(styles.container, styles[`container_${icon}`])}>
        <AutoIntl
          className={iconClassName}
        />
      </div>
    );
  }
}
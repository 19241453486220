import React from 'react';
import { Map as imMap } from 'immutable';

import TMC from '@autonomic/browser-sdk';

import apiFactory from '../../../utils/api';
import { enhanceSdkEndpoint } from '../../../utils/api';
import { history as browserHistory } from '../../../history';
import { redirectOnSaveSuccess } from '../../../utils/linkHelper';
import { UUID_RE } from '../../../constants';
import {
  hasMembershipFilter,
  hasSignalFilter,
  isValidVin
} from '../../utils/taps';
import Tabs from '../../Tabs';
import DefaultCreate from '../Edit';

import styles from '../../../css/components/tap_create.module.scss';

const MODE_MANUAL   = '#manual';
const MODE_ADVANCE  = '#advance';
const MODES         = [MODE_MANUAL, MODE_ADVANCE];

const TABS_ENABLED  = false;

export default class TapCreate extends DefaultCreate {

  componentDidMount() {
    super.componentDidMount();
    window.addEventListener('popstate', this.enforceMode);
    this.enforceMode();
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.enforceMode);
  }

  enforceMode() {
    // copying state from redirect
    const mode = window.location.hash;
    if (!mode || !MODES.includes(mode)) {
      browserHistory.replace({
        hash: MODE_MANUAL,
        state: this.props.location.state
      });
    }
  }

  renderAdvanceView() {
    // TODO implement
    return (
      <div></div>
    );
  }

  renderManualView() {
    return super.renderForm();
  }

  getEntitiesToCreate() {
    const { serviceAlias, entityDef, resources, match, actions } = this.props;
    const { modifiedEntity } = this.state;
    const { entityAlias } = match.params;

    this.endpoint = enhanceSdkEndpoint(
      new TMC.services.Feed(),
      'flows',
      actions
    );

    let modifiedEntityCopy;

    const extraProps = {
      inputFlow: undefined,
      outputFlow: undefined
    };

    const entitiesToCreate = new Map(super.getEntitiesToCreate());

      // add flow(s)
      for (let [attr, { inlineCreate, source }] of Object.entries(entityDef.attributes)) {
        if (inlineCreate && source) {
          const { service: serviceAlias, entity: entityAlias, textProperty } = source;
          const resource = resources.get(`${serviceAlias}-${entityAlias}`, imMap());
          if (resource.size && Object.values(modifiedEntity.outputCreate)[0].displayName && !Object.values(modifiedEntity.outputCreate)[0].foundSelection) {
            const groupKey = `${serviceAlias}-${entityAlias}`;
            const group = entitiesToCreate.get(groupKey) ?? { ...inlineCreate, entities: [] };
            const endpoint = apiFactory(serviceAlias, entityAlias, actions);

            group.entities.push({
              /* key is needed to differentiate between entities */
              key: `${groupKey}-${attr}`,
              idProp: endpoint.idProp,
              data: {
                [textProperty]: Object.values(modifiedEntity.outputCreate)[0].displayName,
                shardCount: Object.values(modifiedEntity.outputCreate)[0].shardCount
              },
              saveFn: data => {
                return endpoint.create(data).then(resp => {
                  extraProps[attr] = resp.data[endpoint.idProp];
                  return resp;
                });
              },
              halt: true,
              displayFields: ['displayName', 'id']
            });

            entitiesToCreate.set(groupKey, group);
          }
        }
      }

    if (modifiedEntity.outputCreate) {
      modifiedEntity.filters = Object.values(modifiedEntity.outputCreate)[0].filters;
      Object.values(modifiedEntity.outputCreate)[0].filters.forEach(filter => {
        if (filter.attributeTagFilter) {
          modifiedEntity.denyAttributeTags = true;
        }
      })
      if (Object.values(modifiedEntity.outputCreate)[0].foundSelection) {
        modifiedEntity.outputFlowId = Object.values(modifiedEntity.outputCreate)[0].aui;
      }
      modifiedEntityCopy = Object.assign({}, modifiedEntity);
      delete modifiedEntityCopy.outputCreate;
    }

    // add our main entity
    entitiesToCreate.set(
      `${serviceAlias}-${entityAlias}`,
      {
        entityType: entityDef.type,
        entities: [{
          key: `${serviceAlias}-${entityAlias}-new`,
          idProp: entityDef.pkField,
          data: {...modifiedEntityCopy},
          saveFn: (data) => {
            const modifiedData = { ...data, ...extraProps };
            // update groups
            return this._saveEntity(
              modifiedData,
              resp => resp,
              error => Promise.reject(error)
            );
          },
          halt: true,
          displayFields: ['displayName', 'id']
        }],
        serviceAlias,
        entityAlias
      }
    );

    return entitiesToCreate;
  }

  onStatusDialogClose = this.onStatusDialogClose.bind(this)
  onStatusDialogClose(created, statuses) {
    if (created) {
      const { serviceAlias, match } = this.props;
      const { entityAlias } = match.params;
      const status = statuses.get(`${serviceAlias}-${entityAlias}-new`);
      redirectOnSaveSuccess(status.resp, this.props.endpoint, match.url);
    }
    else {
      this.getEntitiesToCreate();
      sessionStorage.setItem('created', created)
      this.endpoint.list().then(resp => resp.getItems(Infinity).then(items => {
        const flowOptions = items.map((flow) => ({
          val: flow.id,
          displayString: flow.displayName
        }));
        sessionStorage.setItem('updatedFlows', JSON.stringify(flowOptions));
      }));
      this.setState({ showStatusDialog: false })
    }
  }

  disableSaveBtn = this.disableSaveBtn.bind(this);
  disableSaveBtn(entity) {
    const attributes = this.props.entityDef.attributes;
    let requiredFields = [];
    let requiredEntityFieldsObject = {};
    let emptyValue;
    let hasOutputFlow;
    let hasSignal;
    let hasMembership;
    let numFiltersWithDetails = 0;
    let numFilters = 0;
    let validGroupFilter;
    let validVinFilter;

    Object.entries(attributes).forEach(attribute => {
      if (attribute[1].rules && attribute[1].rules.includes('required') && attribute[1].display?.create !== false) {
        requiredFields.push(attribute[0])
        requiredEntityFieldsObject[attribute[0]] = entity[attribute[0]];
      }
    });

    Object.values(requiredEntityFieldsObject).forEach(field => {
      if (typeof field === 'object') {
        emptyValue = field.length === 0
      }
    });

    if (requiredEntityFieldsObject.outputCreate !== undefined) {
      if ((Object.values(requiredEntityFieldsObject.outputCreate)[0].filters)) {
        hasSignal = hasSignalFilter(Object.values(requiredEntityFieldsObject.outputCreate)[0].filters);
        hasMembership = hasMembershipFilter(Object.values(requiredEntityFieldsObject.outputCreate)[0].filters);
        numFilters = Object.values(requiredEntityFieldsObject?.outputCreate)[0].filters.length;
        Object.values(requiredEntityFieldsObject.outputCreate)[0].filters.forEach(filter => {
          if (filter.groupFilter) {
            validGroupFilter = UUID_RE.test(filter.groupFilter.groupId);
          }
          if (filter.vinFilter) {
            const checkIfDuplicateExists = (arr) => {
              return new Set(arr).size !== arr.length;
            }
            validVinFilter = !checkIfDuplicateExists(filter.vinFilter.vins) && filter.vinFilter.vins?.every(isValidVin)
            if (filter.vinFilter.vins?.length > 0) {
              numFiltersWithDetails++;
            }
          }
          if (filter.deviceFilter || filter.groupFilter || filter.iamFilter || filter.attributeFilter) {
            if (!Object.values(Object.values(filter)[2]).includes('')) {
              numFiltersWithDetails ++;
            }
          } else if (filter.vinFilter) {
            if (filter.vinFilter.vins?.length > 0) {
              numFiltersWithDetails ++;
            }
          } else if (filter.membershipTagFilter || filter.attributeTagFilter) {
            if (!Object.values(Object.values(filter)[2]).includes(undefined)) {
              numFiltersWithDetails ++;
            }
          } else if (filter.signalPassThroughFilter || filter.memberPassThroughFilter) {
            numFiltersWithDetails ++;
          }
        })
      }
      hasOutputFlow = Object.values(requiredEntityFieldsObject.outputCreate)[0].aui !== '';
    }

    return Object.values(requiredEntityFieldsObject).includes(undefined) ||
      Object.values(requiredEntityFieldsObject).includes('') ||
      !hasSignal ||
      !hasMembership ||
      !hasOutputFlow ||
      emptyValue === undefined ||
      numFilters !== numFiltersWithDetails ||
      validGroupFilter === false ||
      validVinFilter === false
  }

  handleOnSave = this.handleOnSave.bind(this);
  handleOnSave(modifiedEntity, doSaveFn) {
    this._saveEntity = doSaveFn;

    this.setState({
      showStatusDialog: true,
      modifiedEntity
    });

    return Promise.resolve();
  }

  renderForm() {
    const mode = window.location.hash;
    const navLinks = [
      { label: 'Manual',  destination: MODE_MANUAL },
      { label: 'Advance', destination: MODE_ADVANCE }
    ].map(link => {
      link.selected = mode === link.destination;
      return link;
    });

    return (
      <div className={styles.form}>
        { TABS_ENABLED && <Tabs className={styles.tabs} navLinks={navLinks} /> }
        { mode === MODE_MANUAL ? this.renderManualView() : this.renderAdvanceView() }
      </div>
    );
  }

}

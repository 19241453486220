import React from 'react';
import * as T from 'prop-types';
import { Map as imMap } from 'immutable';
import IPT from 'react-immutable-proptypes';
import { select } from 'd3-selection';
import { zoom as d3Zoom, zoomTransform } from 'd3-zoom';

import TMC from '@autonomic/browser-sdk';
import { createDeepEqualSelector } from '@au/core/lib/selectors/general';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import NotFound from '@au/core/lib/components/elements/NotFound';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import { ScreenWidthContext} from '../contexts/screenWidthContext';
import { TOPOLOGY_PATH, FLOW_ARN, FORK_ARN, SERVICE_NAMES, NOOP } from '../constants';
import { formatMessage } from '../utils/reactIntl';
import { history } from '../history';
import shared from '../shared';
import { enhanceSdkEndpoint } from '../utils/api';
import { download } from '../utils/download';
import { trackEvent } from '../utils/analyticsHelpers';
import svg2png from '../utils/svg2png';
import { FeedGraph, MultiTree, LayoutOptimizer } from '../utils/topology';
import {
  TopologySvg,
  ArrowHeadMarker,
  positionTreesNodes,
  renderTrees,
  NODE_X_DIM,
  NODE_Y_DIM,
  CIRCLE_NODE_X_DIM,
  CIRCLE_NODE_Y_DIM,
  CIRCLE_NODE_RADIUS
} from './utils/topology';
import { setPageTitle } from "./utils/pageTitle";
import Zoom from './Zoom';
import FeedTopologySearch from './FeedTopologySearch';
import FeedTopologyLegend from './FeedTopologyLegend';
import FeedTopologyTooltipLegend from './FeedTopologyTooltipLegend';

import styles from '../css/components/feed_topology.module.scss';

const { topology: sharedTopologyConfig } = shared;
const X_PADDING = 40;
const svgNS = "http://www.w3.org/2000/svg";
const POPOUT_WIDTH = 1015; // Smallest width for uuids not to wrap in filters grid

const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

export default class FeedTopologyView extends React.Component {

  render() {
    return <FeedTopologyNetworkLoader {...this.props} hideDownload={isFirefox} />;
  }
}

export class FeedTopologyNetworkLoader extends React.Component {
  static propTypes = {
    taps: IPT.map,
    flows: IPT.map,
    processors: IPT.map,
    forks: IPT.map,
    match: T.shape({
      params: T.shape({
        id: T.string
      }).isRequired
    }),
    popoutEnabled: T.bool,
    skipInferredNodes: T.bool,
    hideSearch: T.bool,
    hideDownload: T.bool,
    smallLoader: T.bool,
    onSvgClick: T.func,
    onNodeClick: T.func,
    collapsed: T.bool
  }

  static defaultProps = {
    popoutEnabled: true,
    hideSearch: false,
    hideDownload: false,
    smallLoader: false,
    skipInferredNodes: false,
    collapsed: false
  }

  feedService = new TMC.services.Feed();

  // Updating label here to align with entity frameworks
  flowsEndpoint = enhanceSdkEndpoint(
    { ...this.feedService, label: this.feedService.label },
    'flows',
    this.props.actions
  )

  tapsEndpoint = enhanceSdkEndpoint(
    { ...this.feedService, label: this.feedService.label },
    'taps',
    this.props.actions
  )

  processorsEndpoint = enhanceSdkEndpoint(
    { ...this.feedService, label: this.feedService.label },
    'processors',
    this.props.actions
  )

  forksEndpoint = enhanceSdkEndpoint(
    { ...this.feedService, label: this.feedService.label },
    'forks',
    this.props.actions
  )

  componentDidMount() {
    const { flows, taps, processors, forks, actions } = this.props;
    setPageTitle(actions.setPageTitle, [{ displayId: 'au.section.title.tmcTopology' }]);
    createResponseAlertMessage('clearEvents')

    if (!flows) {
      this.flowsEndpoint.listPages({ pagesToLoad: Infinity }).catch(createResponseAlertMessage);
    }
    if (!taps) {
      this.tapsEndpoint.listPages({ pagesToLoad: Infinity }).catch(createResponseAlertMessage);
    }
    if (!processors) {
      this.processorsEndpoint.listPages({ pagesToLoad: Infinity }).catch(createResponseAlertMessage);
    }
    if (!forks) {
      this.forksEndpoint.listPages({ pagesToLoad: Infinity }).catch(createResponseAlertMessage);
    }
  }

  render() {
    const { flows, taps, processors, forks, routes, match, actions, collapsed } = this.props;
    const isFetching = Boolean([flows, taps, processors, forks]
      .filter(ftpf => !ftpf)
      .length);
    return (
      <FeedTopologyLayoutGenerator
        flows={flows}
        taps={taps}
        processors={processors}
        forks={forks}
        routes={routes}
        isFetching={isFetching}
        selected={match.params.id || match.params.entityId}
        actions={actions}
        hideSearch={this.props.hideSearch}
        hideDownload={this.props.hideDownload}
        smallLoader={this.props.smallLoader}
        onSvgClick={this.props.onSvgClick}
        onNodeClick={this.props.onNodeClick}
        skipInferredNodes={this.props.skipInferredNodes}
        popoutEnabled={this.props.popoutEnabled}
        collapsed={collapsed}
      />
    );
  }
}

export class FeedTopologyLayoutGenerator extends React.PureComponent {
  static propTypes = {
    taps: IPT.map,
    flows: IPT.map,
    processors: IPT.map,
    forks: IPT.map,
    isFetching: T.bool.isRequired,
    selected: T.string,
    actions: T.object,
    popoutEnabled: T.bool,
    skipInferredNodes: T.bool,
    hideSearch: T.bool,
    hideDownload: T.bool,
    smallLoader: T.bool,
    onSvgClick: T.func,
    onNodeClick: T.func,
    collapsed: T.bool
  }

  static defaultProps = {
    popoutEnabled: true,
    skipInferredNodes: false,
    hideSearch: false,
    hideDownload: false,
    smallLoader: false,
    collapsed: false,
    routes: imMap()
  }

  state = {}

  static generateLayout = createDeepEqualSelector(
    args => args,
    ({ flows, taps, processors, forks, routes, maxIterations, skipInferredNodes }) => {
      const graph = new FeedGraph(flows, taps, processors, forks, routes, skipInferredNodes);
      const trees = new Map(graph.getRootNodes().index
        .map(([name, rNode]) => [name, graph.getTree(rNode)]));
      const mTree = new MultiTree(trees);

      const optimizer = new LayoutOptimizer(mTree, maxIterations);
      return optimizer
        .run()
        .then(layout => ({ ...layout, graph }));
    }
  )

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  generateLayout(maxIterations) {
    const { skipInferredNodes } = this.props;
    // Sort by ID so we seed the state consistently
    const flows = Object.values(this.props.flows.toJS())
      .sort((a,b) => a.id < b.id ? -1 : 1);
    const taps = Object.values(this.props.taps.toJS())
      .sort((a,b) => a.id < b.id ? -1 : 1);
    const processors = Object.values(this.props.processors.toJS())
      .sort((a,b) => a.id < b.id ? -1 : 1);
    const forks = Object.values(this.props.forks.toJS())
      .sort((a,b) => a.id <b.id ? -1 : 1);
    const routes = Object.values(this.props.routes.toJS())
      .sort((a,b) => a.id <b.id ? -1 : 1);

    this.constructor.generateLayout({ flows, taps, processors, forks, routes, maxIterations, skipInferredNodes })
      .then(layout => this._isMounted && this.setState({ layout }));
  }

  render() {
    const { layout } = this.state;
    const { isFetching, flows, taps, processors, forks, selected, actions, popoutEnabled, hideSearch, hideDownload, collapsed } = this.props;

    if (!isFetching && flows.size === 0 && taps.size === 0 && processors.size === 0 && forks.size === 0) {
      return <AutoIntl className={styles.nodata} displayId="au.table.dataEmptyMessage" tag="div" />;
    }

    if (!isFetching && !layout && (flows.size || taps.size || processors.size || forks.size)) {
      this.generateLayout(3); // at roughly 5 seconds makes 15 total
    }

    if (layout) {
      const idOrAui = selected && decodeURIComponent(selected); // because `decodeURIComponent(undefined) === 'undefined'`
      const aui = flows.getIn([idOrAui, 'flowAui']) || taps.getIn([idOrAui, 'tapAui']) || processors.getIn([idOrAui, 'aui']) || forks.getIn([idOrAui, 'aui']) || (layout.graph.nodes.get(idOrAui) || {}).aui;

      if (idOrAui && !aui) {
        return <NotFound history={history} />;
      }

      // TODO: use more granular injectScreenWidth from au-core
      return (
        <ScreenWidthContext.Consumer>
          { screenWidth =>
            <FeedTopology
              layout={layout}
              selected={aui}
              screenWidth={screenWidth}
              actions={actions}
              popoutEnabled={popoutEnabled}
              hideSearch={hideSearch}
              hideDownload={hideDownload}
              onSvgClick={this.props.onSvgClick}
              onNodeClick={this.props.onNodeClick}
            />
          }
        </ScreenWidthContext.Consumer>
      );
    }
    return (
      <div className={styles.topology_legend_container}>
        <FeedTopologyLegend smallLoader={this.props.smallLoader} collapsed={collapsed}/>
      </div>
    );
  }
}

// add selector to prop provider to make use of the PureComponent
export class FeedTopology extends React.Component {
  static propTypes = {
    layout: T.object.isRequired,
    selected: T.string,
    screenWidth: T.string.isRequired,
    actions: T.shape({
      openEntityPage: T.func.isRequired,
      closePopout: T.func.isRequired
    }).isRequired,
    popoutEnabled: T.bool,
    hideSearch: T.bool,
    hideDownload: T.bool,
    onSvgClick: T.func,
    onNodeClick: T.func
  }

  static defaultProps = {
    popoutEnabled: true,
    hideSearch: false,
    hideDownload: false,
  }

  componentDidMount() {
    this.renderD3();
  }

  componentWillUnmount() {
    // Clean up Popout
    this.onPopoutClose();
  }

  componentDidUpdate() {
    this.renderD3();
  }

  // ignore `selected` changes
  shouldComponentUpdate(nextProps) {
    const { layout } = this.props;
    if (layout !== nextProps.layout) {
      return true;
    }
    return false;
  }

  setControlsRef = this.setControlsRef.bind(this);
  setControlsRef(ref) {
    this.controlsElemRef = ref;
  }

  changeZoom(delta) {
    if (!this.zoom) return;
    const { k } = zoomTransform(this.svg.node());
    this.zoom.scaleTo(this.svg, Math.pow(2, delta) * k);
  }

  zoomIncrease = this.zoomIncrease.bind(this);
  zoomIncrease() {
    this.changeZoom(0.5);
  }

  zoomDecrease = this.zoomDecrease.bind(this);
  zoomDecrease() {
    this.changeZoom(-0.5);
  }

  zoomTo(aui, { xOffset=0, yOffset=0, duration=750 }={}) {
    const node = document.querySelector(`[data-aui="${aui}"]`);
    if (!node) return;

    this.zoom
      .translateTo(
        this.svg.transition().duration(duration),
        parseInt(node.getAttribute('x'), 10) + xOffset + NODE_X_DIM / 2,
        parseInt(node.getAttribute('y'), 10) + yOffset + NODE_Y_DIM / 2
      );
  }

  highlightDataFlow(aui, direction) {
    const { layout } = this.props;

    const pathEls = document.querySelectorAll(`.${styles.container} path[data-from]`);
    const afterLastPath = pathEls[pathEls.length-1]?.nextSibling;

    const paths = layout.graph.getPathsToLeafs(aui, direction);
    for (let path of paths) {
      if (path.length === 1) continue;
      if (direction === 'input') path.reverse();
      for (let i=1; i<path.length; ++i) {
        const [from, to] = [path[i-1], path[i]];
        if (['STOPPED', 'PAUSED'].includes(from.state)) break;

        document.querySelectorAll(`[data-aui="${from.aui}"]`)
          .forEach(el => el.classList.add(styles['highlight_' + direction]));
        document.querySelectorAll(`[data-aui="${to.aui}"]`)
          .forEach(el => el.classList.add(styles['highlight_' + direction]));

        const el = [...document.querySelectorAll(`path[data-from="${from.aui}"][data-to="${to.aui}"]`)][0];
        el?.classList.add(styles['highlight_' + direction]);

        if (el) {
          // make sure path is not covered by others
          afterLastPath?.parentNode.insertBefore(el, afterLastPath);
        }
      }
    }
  }

  highlightNode(aui) {
    // clear previous highlighting state
    this.clearHighlights();

    // lower opacity of all else
    for (let el of document.querySelector(`.${styles.container} svg > g`).children) {
      if (el.dataset.aui === aui) continue;
      el.classList.add(styles.dim);
    }

    this.highlightDataFlow(aui, 'output');
    this.highlightDataFlow(aui, 'input');

    const node = document.querySelector(`[data-aui="${aui}"]`);
    if (!node) return;

    //Adds polygon around fork icon
    if(aui.startsWith(FORK_ARN)){
      const polygon = this.highlightFork(node.getAttribute('x'), node.getAttribute('y'));
      node.parentNode.insertBefore(polygon, node);
      return;
    }

    //Adds rectangle or circle around all other icons
    const rect = document.createElementNS(svgNS, 'rect');
    // TODO: Find a better way to check if our icon is round
    // currently only flows have "circle" nodes
    const isCircleNode = aui.startsWith(FLOW_ARN);
    rect.setAttributeNS(null, 'width', (isCircleNode ? CIRCLE_NODE_X_DIM : NODE_X_DIM));
    rect.setAttributeNS(null, 'height', (isCircleNode ? CIRCLE_NODE_Y_DIM : NODE_Y_DIM));
    rect.setAttributeNS(null, 'x', node.getAttribute('x'));
    rect.setAttributeNS(null, 'y', node.getAttribute('y'));

    if (isCircleNode) {
      // `rx` defines a radius on the x-axis. see: https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/rx
      rect.setAttributeNS(null, 'rx', CIRCLE_NODE_RADIUS);
    }
    rect.classList.add(styles.selected_halo);
    node.parentNode.insertBefore(rect, node);
  }

  clearHighlights() {
    for (let el of document.querySelectorAll(`.${styles.highlight_output}, .${styles.highlight_input}`)) {
      el.classList.remove(styles.highlight_output);
      el.classList.remove(styles.highlight_input);
    }
    for (let el of document.querySelectorAll(`.${styles.dim}`)) {
      el.classList.remove(styles.dim);
    }
    for (let el of document.querySelectorAll(`.${styles.selected_halo}`)) {
      el.parentNode.removeChild(el);
    }
  }

  highlightFork(x, y){
    const polygon = document.createElementNS(svgNS, "polygon");
    const pointsX = [0,18,36,36,18,0];
    const pointsY = [4,0,4,32,36,32];
    const nodeX = parseInt(x);
    const nodeY = parseInt(y);

    let pointsStr = "";
    for (let i = 0; i< pointsX.length; i++){
      const pointX = pointsX[i] + nodeX;
      const pointY = pointsY[i] + nodeY;
      pointsStr += pointX + "," + pointY + " ";
    }

    polygon.setAttributeNS(null, "points", pointsStr);
    polygon.classList.add(styles.selected_halo);

    return polygon;
  }

  onPopoutClose = this.onPopoutClose.bind(this);
  onPopoutClose() {
    this.controlsElemRef.classList.remove(styles.popout_controls);
    this.props.actions.closePopout();
  }

  popoutOnStateChange(aui, data) {
    // Update our currently selected tap based on actions taken in Popout
    if (Object.keys(data)) {
      select(`[data-aui="${aui}"]`).attr('xlink:href', n => {
        let def = n.type;
        if (n.type === 'tap' || n.type === 'processor') {
          if (data.state === 'PAUSED') {
            def += '-paused';
          }
          if (data.state === 'STOPPED') {
            def += '-stopped';
          }
        }
        return '#' + def;
      });
    }
  }

  selectNode(aui, inferred=false) {
    const { layout, actions, screenWidth, popoutEnabled } = this.props;
    const selectedNode = layout.graph.nodes.get(aui);
    const entityAlias = `${selectedNode.type}s`; // generally plural
    const popoutHeader = <div className={styles.header}>{selectedNode.displayName}</div>;
    const props = {
      nuevo: true,
      initialWidth: POPOUT_WIDTH,
      onCloseClick: this.onPopoutClose,
      popoutHeader,
      baseUrl: `/services/${SERVICE_NAMES.FEED}`,
      componentProps: {
        onStateChange: this.popoutOnStateChange.bind(this, aui),
        inferredEntity: inferred
      }
    };

    if (screenWidth === 'desktop' && popoutEnabled) {
      this.highlightNode(aui);

      // Adjust selected node and controls to account for Popout in view
      this.zoomTo(aui, { xOffset: POPOUT_WIDTH / 2 });
      if (this.controlsElem) {
        this.controlsElem.classList.add(styles.popout_controls);
      }

      //FIXME - MOVE TO linkHelper
      history.push(`${TOPOLOGY_PATH}/${encodeURIComponent(aui)}`);

      trackEvent({
        element: aui,
        action: 'ViewPopout',
        page: 'FeedTopology'
      });

      // Opens `ConnectedTopologyEntityView` in Popout (TopologyEntityView container)
      // params - entityType, entityAlias, entityId, action, entryId, props
      const popoutProps = {
        props,
        componentName: 'ConnectedPopoutEntityView',
        componentProps: {
          params: {
            entityId: selectedNode.id || selectedNode.aui,
            entryId: null,
            entityAlias,
            serviceAlias: SERVICE_NAMES.FEED
          },
          key: selectedNode.aui,
          popoutProps: props.componentProps
        }
      };
      actions.openPopout(popoutProps);
    }
    else if (!inferred) {
      //FIXME - MOVE TO linkHelper
      history.push(`/services/${SERVICE_NAMES.FEED}/${entityAlias}/${selectedNode.id}/view`);
    }
    else if (popoutEnabled) {
      // inferred node on mobile
      //FIXME - MOVE TO linkHelper
      history.push(`${TOPOLOGY_PATH}/${encodeURIComponent(aui)}`);
      this.zoomTo(aui);
    }
  }

  onNodeClick = this.onNodeClick.bind(this);
  onNodeClick(event, { aui, inferred }) {
    event.stopPropagation(); // stop onSvgClick() from getting called
    inferred ? NOOP : this.selectNode(aui, inferred);
  }

  onSvgClick = this.onSvgClick.bind(this);
  onSvgClick() {
    if (this.props.onSvgClick) {
      this.props.onSvgClick();
    }
    else {
      this.clearHighlights();
      this.onPopoutClose();

      //FIXME - MOVE TO linkHelper
      history.push(TOPOLOGY_PATH);
    }
  }

  onSearch = this.onSearch.bind(this);
  onSearch(searchText) {
    if (searchText.length) {
      this.highlightNode(searchText);
      this.selectNode(searchText);
    }
  }

  onSearchChange = this.onSearchChange.bind(this);
  onSearchChange(searchText) {
    // lower opacity of anything not in searchText
    for (let el of document.querySelector(`.${styles.container} svg > g`).children) {
      if (el.dataset.aui && el.dataset.aui.includes(searchText)) {
        el.classList.remove(styles.dim);
        continue;
      }
      el.classList.add(styles.dim);
    }
  }

  async renderD3() {
    const { layout, selected, popoutEnabled } = this.props;

    this.svg = select(`.${styles.container} svg`);
    this.g = this.svg.append('g');

    if (sharedTopologyConfig.layout !== layout) {
      sharedTopologyConfig.layout = layout;
      sharedTopologyConfig.transform = null;
    }

    this.zoom = d3Zoom().scaleExtent([0.25, 2]).on('zoom', ({ transform }) => {
      this.g.attr('transform', transform);
      sharedTopologyConfig.transform = transform;
    });
    this.svg.call(this.zoom);

    // check if this is a first render but we have already transformed this
    // rendered layout
    if (sharedTopologyConfig.transform) {
      this.zoom.transform(this.svg, sharedTopologyConfig.transform);
    }

    const nonCrossTrees = [];
    const orphanNodes = [];
    for (let [treeId, tree] of layout.mTree.trees) {
      if (!layout.mTree.crossTrees.has(treeId) && tree._root.input.size === 0 && tree._root.output.size !== 0) {
        nonCrossTrees.push(tree);
      } else if (tree._root.input.size === 0 && tree._root.output.size === 0) {
        orphanNodes.push(tree);
      }
    }

    nonCrossTrees.sort((a, b) => a._root.displayName < b._root.displayName ? -1 : 1);
    orphanNodes.sort((a, b) => a._root.displayName < b._root.displayName ? -1 : 1);
    const trees = new Map([...layout.assignedMTree.trees, ...nonCrossTrees.map(t => [t.id, t]), ...orphanNodes.map(t => [t.id, t])]);
    positionTreesNodes(trees, X_PADDING);
    renderTrees(layout.mTree, trees, this.g, this.onNodeClick);

    // highlight and zoom to selected node on initial render
    if (selected) {
      this.highlightNode(selected);
      this.zoomTo(selected);
      // FIXME: (Firefox) - Handle case where we have already visited the togology view and are revisting.
      // In this case push `zoomTo` to the back of the event queue
      // making sure everything is in order before trying to zoom for accuracy
      if (popoutEnabled) setTimeout(() => this.selectNode(selected));
    }
  }

  exportSvg = this.exportSvg.bind(this);
  exportSvg() {
    let transform = this.g.attr('transform');
    // undo any zoom/position transformations before exporting
    this.g.attr('transform', '');

    svg2png(this.svg.node(), dataUrl => {
      download(dataUrl, 'tmc_topology_overview.jpeg');
    });

    // restore previous zoom/position transformations
    this.g.attr('transform', transform);
  }

  reCenterView = this.reCenterView.bind(this);
  reCenterView() {
    const { selected } = this.props;

    if (selected) {
      const node = document.querySelector(`[data-aui="${selected}"]`);
      if (!node) return;
      this.zoomTo(selected, { scale: 1 });
    }
  }

  render() {
    const { layout, selected, hideSearch, hideDownload } = this.props;
    const [...nodeAuis] = layout.graph.nodes.keys();
    const searchOptions = nodeAuis.map(aui => ({val: aui, displayString: aui }));

    return (
      <div className={styles.container}>
        <TopologySvg onClick={this.onSvgClick}>
          <defs>
            <ArrowHeadMarker id="arrowhead-highlight-input" />
          </defs>
          <defs>
            <ArrowHeadMarker id="arrowhead-highlight-output" />
          </defs>
        </TopologySvg>
        <div ref={this.setControlsRef}>
          {!hideSearch &&
            <FeedTopologySearch
              className={styles.search}
              onChange={this.onSearchChange}
              onSearch={this.onSearch}
              options={searchOptions}
              caption={formatMessage({ id: 'au.topology.label.search' })}
            />
          }
          <Zoom 
            className={styles.zoom} 
            onPlusClick={this.zoomIncrease} 
            onMinusClick={this.zoomDecrease} 
            caption={formatMessage({ id: 'au.topology.label.zoom' })}
          />
          <FeedTopologyTooltipLegend 
            className={styles.tooltip_legend} 
            caption={formatMessage({ id: 'au.topology.label.legend' })}
          />
          { !hideDownload &&
            <button
              className={styles.export_svg}
              onClick={this.exportSvg}
              data-caption={formatMessage({ id: 'au.topology.label.download' })}
            />
          }
          { Boolean(selected) && 
            <button 
              className={styles.re_center} 
              onClick={this.reCenterView} 
              data-caption={formatMessage({ id: 'au.topology.label.recenter' })} 
            />
          }
        </div>
      </div>
    );
  }
}
